export const ServiceUrl = {
  SETTINGS: "v1/settings?type=socialmedia,billheader",
  COURSES: "v1/set/list-courses",
  DEPARTMENTS: "v1/set/list-dept",

  MENUS: "site/v1/menus/list-menus",
  LINKS: "site/v1/menus/list-link",
  GET_PAGE: "site/v1/page/get",
  GET_IMAGES: "site/v1/page/get-images",
  GET_YEAR: "site/v1/page/get-year",
  GET_EVENT: "site/v1/page/get-event",
  LIST_PAGES: "site/v1/page/list",

  // Department pages
  DEPT: {
    SLIDERS: "site/v1/dept/sliders",
    PAGE: "site/v1/dept/page",
    COURSES: "site/v1/dept/courses",
    FILES: "site/v1/dept/files",
      LIST_FILES: "site/v1/dept/list-files",
    FACULTY: "site/v1/dept/faculty",
    ESSTABLISHMENT: "site/v1/dept/esstablishment",
  },

  LIST_SLIDERS: "site/v1/page/sliders",
  RECENT_GALLERY: "site/v1/page/recent-gallery",
  GALLERY: "site/v1/page/gallery",
};
